@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro";
  src: url("../public/fonts/SF-Pro.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, "SF Pro", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}
